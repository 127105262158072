<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import AcademyMainPage from '@core/slices/academy/home/home.vue';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { HttpRejection } from '@model/http/rejection';
import { getOgImage } from '@utils/html-meta';

export default Vue.extend({
    name: 'AcademyHomeContainer',

    async serverPrefetch(): Promise<void> {
        const { locale } = this.$route.params;

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 'global-meta', locale: LOCALE_DEFAULT }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-academy-acronis-com', locale }),
            this.$store.dispatch('academy/getCourses'),
        ]);

        if (this.preset.locale !== locale) {
            throw new HttpRejection(`Premature rendering stop: '${locale}' not found`, StatusCodes.NOT_FOUND);
        }

        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
        ...mapState({
            preset: (state: any) => state.slices.items['s-academy-acronis-com'] || {},
        }),
    },

    methods: {
        getMeta(): any {
            const settings = this.preset?.template?.['main-page']?.settings || {};
            const title = settings.meta?.title || 'Acronis';
            const keywords = settings.meta?.keywords || 'Acronis';
            const description = settings.meta?.description || 'Acronis';
            const path = this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE;
            const globalMeta = this.$store.state.slices.items['global-meta'] || {};
            const imgSrc = settings.meta?.image || `@${globalMeta.defaultOgImage}`;
            const ogimage = getOgImage(imgSrc, path);
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogimage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogimage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'meta', name: 'keywords', content: keywords },
                    { tag: 'link', rel: 'image_src', href: ogimage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                htmlAttrs: {
                    dir: 'ltr',
                    lang: 'en',
                },
            };
        },
    },

    render(h) {
        return h(AcademyMainPage);
    },
});
</script>
